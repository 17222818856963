.search-view {
  .card {
    .result-infos {
      font-size: 0.8rem;
      color: $grey-blue;
      .date {
        flex: 1 0 9rem;
      }
      .url {
        text-align: right;
      }
    }
  }
}
