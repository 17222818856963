.card {
  .card-body {
    .category-top {
      color: $text-color;

      a.category {
        color: $text-color;
      }
    }

    .categoryicon-top {
      .icon {
        width: $card-category-icon-size;
        height: $card-category-icon-size;
      }
    }

    .card-title {
      a {
        font-size: 1.35rem;
        line-height: 1.7rem;
      }
    }

    .card-text {
      color: $text-color;
      font-size: 0.9rem;
      line-height: 1.5;
    }
  }
}
