.contentInEvidenceTemplate {
  .row.full-width {
    height: auto;
  }
  @media (min-width: #{map-get($grid-breakpoints, xl)}) {
    .card-over-image {
      position: absolute;
      top: 50%;
      left: -65%;
      max-height: 80%;
      transform: translateY(-50%);
    }
  }

  .card {
    .card-body {
      .card-title {
        a {
          color: $secondary;
          font-size: 2.5rem;
          line-height: 1.2em;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .read-more {
    color: $link-color;
    text-decoration: none;

    .icon {
      fill: $link-color;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}
