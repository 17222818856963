// fix image height for slider with only one image
.it-carousel-wrapper .slider-container .slick-list .slick-track .slick-slide .it-single-slide-wrapper a {
    height: inherit;
}

#field-testo_portlet {
  .old-portlet-wrapper {
    margin-bottom: 1em;
    padding-bottom: 1em;
    border: 1px solid #c7d5d8;
    border-radius: 0.5em;
    padding: 0.5em;

    iframe {
      max-width: 100%;
    }

    ul {
      padding-left: 0;
      margin-left: 0;
    }
  }
}

.has-toolbar {
  .full-width {
    @media (max-width: #{map-get($grid-breakpoints, md)}) {
      left: 50%;
      width: 100vw !important;
    }
  }
}

.color-list-widget {
  .ui.button {
    &.highlight {
      background-color: $bg-highlight;
    }

    &.white {
      background-color: #fff;
    }
    &.grey-blue {
      background-color: $grey-blue;
    }

    &.default {
      background-color: $gray-100;
    }
  }
}
