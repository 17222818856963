.it-nav-wrapper {
  .it-header-center-wrapper {
    .it-header-center-content-wrapper {
      //right zone
      .it-right-zone {
        @media (max-width: #{map-get($grid-breakpoints, lg)}) {
          .it-socials {
            display: none !important;
          }
        }
      }
    }
  }
}
