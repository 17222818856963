.content-image figure img {
  height: auto;
}

#metadata {
  h2,
  h4,
  h4 {
    font-family: $font-family-sans-serif;
  }
}
