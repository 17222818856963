//site
$primary-h: 199.22;
$primary-s: 69.13;
$primary-b: 58.43;

$secondary: #002b54;

$tertiary: #fed505;
$tertiary-text: $secondary;
$tertiary-text-hover: $secondary;

$text-color: #3f4142;
$gray-border: #e5e5e5;

$header-bg-color: $secondary;
$header-center-bg-color: $header-bg-color;

$headerslim-bg-color: #1a3f64;

$headings-color: $text-color;
$font-weight-semibold: 600;

$btn-font-size: 1rem;
$btn-font-weight: 700;

$navigation-light-megamenu-separator-color: transparent;

$bg-highlight: #e6f4f1; //verdino chiaro
$card-category-icon-size: 18px;
//bg colors for listing
$grey-blue: #3c4662;

//$header-slim-bg-color: #002a53;
//$header-slim-text-color: $white;

$argument-icon-color: $secondary;
$argument-icon-bg: rgba($secondary, 0.07);
