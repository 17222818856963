.breadcrumb-container {
  .breadcrumb {
    .breadcrumb-item {
      a {
        color: $primary;
        text-decoration: none;
        font-weight: normal;
        font-size: 16px;
      }
      span.separator {
        font-weight: 300;
        padding: 0 0.3em;
      }
    }
  }
}
