.servizi-online-template {
  position: relative;
  height: auto;

  .background {
    position: absolute;
    overflow: hidden;
    z-index: -1;

    top: 0;
    left: -50%;
    width: 100%;

    height: 100%;

    background-color: $secondary;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    transform: translateX(50%);
  }

  .container {
    padding: 2em 0;

    h2 {
      color: $secondary;
    }

    .items {
      margin-right: -16px;
      margin-left: -16px;

      .card {
        border-left: 5px solid $tertiary;

        a {
          text-decoration: none;
          &:hover,
          &:active {
            .card-title {
              text-decoration: underline;
              .icon-wrapper {
                background-color: $tertiary;
              }
            }
          }
        }

        .card-body {
          .card-title {
            display: flex;
            color: $link-color;
            font-size: 0.8rem;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            align-items: center;

            .icon-wrapper {
              margin-right: 1em;
              background-color: $neutral-1-a2;
              border-radius: 100%;
              width: 2.5em;
              height: 2.5em;

              display: flex;
              align-items: center;
              justify-content: space-around;

              color: black;

              svg.icon {
                width: 1.5em;
                height: 1.5em;
                fill: black;
              }
            }
          }

          p.card-text {
            font-family: $font-family-sans-serif;
          }
        }
      }
    }
    .link-button {
      text-align: center;
      margin-top: 1em;
    }
  }
}
