.block.listing {
  &,
  .public-ui {
    .items-color-highlight {
      .card {
        background-color: $bg-highlight;

        .card-title {
          a {
            color: $black;
          }
        }
      }

      .ribbon-card-template {
        .card {
          .flag-icon {
            background-color: #fff;

            &::after {
              border-bottom-color: $bg-highlight;
            }
          }
        }
      }
    }

    .bg-grey-blue {
      h2 {
        color: #fff;
      }

      .link-more {
        &,
        a {
          color: #fff;

          &:hover,
          &:active {
            color: #ffffffdb;
          }
        }
      }

      .pagination .page-item {
        cursor: pointer;

        &:hover,
        &:active {
          background-color: rgba(255, 255, 255, 0.13);
        }

        .page-link {
          border-color: #fff;
          color: #fff;

          .icon.icon-primary {
            fill: #fff !important;
          }
        }
      }
    }
  }
}
