.documentDescription {
  color: $text-color;
}

.bg-highlight {
  background-color: $bg-highlight;
}

.bg-grey-blue {
  background-color: $grey-blue !important;
}
