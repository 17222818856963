.megamenu {
  .dropdown-menu {
    .link-list-wrapper {
      ul.link-list {
        &.bordered {
          li {
            &:first-of-type,
            &:last-of-type,
            & {
              border: none;
            }
            &:hover {
              background-color: #f7f7f8;
            }
          }
        }

        li {
          line-height: 1.8em;

          a {
            font-size: 1em;

            span {
              color: $text-color;
              font-weight: 400;
            }
          }

          &.it-more {
            a {
              span {
                color: $primary;
                font-weight: $font-weight-bold;
                text-transform: uppercase;
                margin-right: 0.6em;
              }

              svg {
                fill: $primary;
              }
            }
          }
        }
      }
    }
  }
}
