.it-header-center-wrapper {
  font-weight: 400;

  .it-header-center-content-wrapper {
    .it-brand-wrapper {
      a {
        h3 {
          color: $tertiary;
          font-weight: bold;
        }
      }
    }
  }
}
