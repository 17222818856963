.public-ui,
.cms-ui {
  .callout-bg {
    background-color: $bg-highlight;
  }

  .draftjs-buttons {
    a {
      text-decoration: none;
    }
  }
}
