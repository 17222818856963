#correlato-in-evidenza {
  @media (min-width: #{map-get($grid-breakpoints, xl)}) {
    .card {
      flex-direction: row-reverse;

      .card-body {
        flex: 0 1 50%;
      }

      .img-responsive-wrapper {
        flex: 0 1 50%;
        border-bottom-right-radius: 4px;
        border-top-left-radius: 0;

        .img-responsive {
          padding-bottom: 70%;

          figure {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
