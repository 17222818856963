.argumentsServicesInEvidence {
  position: relative;

  &.edit-mode {
    // background: rgba($secondary, 0.44);
  }

  .container {
    position: relative;
    z-index: 2;
  }

  figure {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    display: flex;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    align-items: center;
    justify-content: center;

    img {
      min-width: 100%;
      min-height: 100%;
      object-fit: cover;
      object-position: center;
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($secondary, 0.44);
      content: '';
    }
  }

  .card.card-bg {
    border-radius: 4px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);

    p.card-text {
      margin-bottom: 1.25rem;
    }

    a.read-more {
      color: $link-color;

      &:hover,
      &:focus {
        color: $link-hover-color;
      }
    }

    .argumentOrService-text {
      p {
        margin-bottom: 0.5rem;
      }
    }

    .listInEvidence {
      padding-top: calc(36px + 24px + 3rem);

      h3.card-title {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 24px;
        background: $tertiary;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }

      .listInEvidence-text {
        ul {
          padding: 0;
          margin: 0;
          list-style: none;

          li {
            padding: 10px;
            box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);

            + li {
              margin-top: 1em;
            }

            a {
              color: $link-color;

              &:hover,
              &:focus {
                color: $link-hover-color;
              }
            }
          }
        }
      }
    }
  }
}
