.navbar {
  .navbar-collapsable {
    .menu-wrapper {
      .nav-item {
        &.megamenu {
          .megamenu-toggle-icon {
            margin-right: -0.5em;
          }

          .dropdown-menu {
            .link-list-wrapper {
              ul.link-list {
                li {
                  h3 {
                    color: $primary;
                    font-size: 1em;
                  }
                }

                &.bordered {
                  li:hover {
                    background-color: unset;

                    a {
                      background-color: #f0f0f0;
                    }
                  }
                }
              }
            }

            .dropdownmenu-blocks-column {
              .block {
                &.image {
                  &.align.full {
                    img.full-width {
                      height: unset;
                    }
                  }
                }
              }
            }

            /*@media (min-width: #{map-get($grid-breakpoints, lg)}) {
              > .row {
                > .col-lg-6 {
                  @include .mixin-col-9;
                }

                > .dropdownmenu-blocks-column {
                  &.col-lg-6 {
                    @include .mixin-col-3;
                  }
                }
              }
            }*/

            @media (max-width: #{map-get($grid-breakpoints, lg)}) {
              > .row {
                > .dropdownmenu-blocks-column {
                  &.col-lg-6 {
                    width: 50%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
