.event-search {
  .search-container {
    width: 95%;
    padding-left: 0;
    padding-right: 0;

    .filter-wrapper {
      &.text-filter {
        min-width: 35%;
      }

      &.select-filter {
        min-width: 100px;
        border-bottom: 1px solid #fff;
      }
    }
  }
}
