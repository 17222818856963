//mobile
.navbar {
  &.it-navscroll-wrapper {
    .link-list-wrapper {
      ul {
        li {
          a {
            font-size: 0.9em;
            font-weight: 400;
            &.active {
              font-weight: $font-weight-bold;
            }
          }
        }
      }
    }
  }
}
