//Tablet horizontal / small desktop
@media (min-width: #{map-get($grid-breakpoints, lg)}) {
  .it-header-navbar-wrapper {
    .menu-wrapper {
      border-top: 1px solid $neutral-1-a2;

      .navbar-nav {
        li {
          a.nav-link {
            font-weight: $font-weight-bold;
          }
        }

        &.navbar-secondary {
          flex-wrap: wrap;

          li {
            a.nav-link {
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}
