.after-content {
  .portlets-wrapper {
    margin-top: 1em;
    margin-bottom: 2em;

    > div {
      display: flex;
      flex-wrap: wrap;

      @media (max-width: #{map-get($grid-breakpoints, lg)}) {
        flex-direction: column;
      }
    }

    .old-portlet-wrapper {
      flex: 0 0 30%;
      overflow: hidden;
      border-color: $gray-border;
      margin: 0;
      margin-bottom: 2em;
      border-radius: 0.2em;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;

      .portlet-title,
      .portlet-text {
        padding: 1rem 1.5rem;
      }

      .portlet-title {
        border-bottom: 1px solid $gray-border;
        color: $primary;
        font-size: 1.3em;
        font-weight: bold;
        background-color: #f7f7f7;
        width: 100%;
      }

      .portlet-text {
        img {
          max-width: 100%;
        }

        iframe {
          max-width: 100%;
        }
      }
    }
  }
}
